import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/SEO/SEO';

const Title404 = styled.h2`
  transform-origin: center center;
  transform: rotate(-3.142deg) translatey(-1rem);
`;

export default () => {
  return (
    <Layout>
      <SEO title='Page not found' pathname='/404/' />
      <div
        style={{
          padding: '0 1rem',
          transform: 'rotate(-3.142deg)',
        }}
      >
        <Title404>Uh oh!</Title404>
        <p>
          If you were looking the for the 404 page, great job! Strange choice of page to visit, but
          great job anyway!
        </p>
        <p>
          If you weren't looking the 404 page.. you found it anyway. But don't worry, there's plenty
          of stuff to have a look at here. Why not have a look at some of my{' '}
          <Link to='/blog'>articles</Link>. If you fancy nerding out, try this one on{' '}
          <Link to='/blog/new-product-diffusion-and-the-bass-model/'>New Product Diffusion</Link> or
          one on{' '}
          <Link to='/blog/why-small-businesses-need-a-mission-statement'>
            Why small businesses need a mission statement
          </Link>
          . See you soon!
        </p>
      </div>
    </Layout>
  );
};
